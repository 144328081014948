import React from "react";
import { Link } from "gatsby";
import { css } from "@emotion/react";
import Footer from "../components/footer";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub, faLinkedin } from "@fortawesome/free-brands-svg-icons";

const ListLink = (props) => (
	<li
		css={css`
			display: inline;
			margin-right: 1rem;
		`}
	>
		<Link to={props.to}>{props.children}</Link>
	</li>
);

const ExternalListLink = (props) => (
	<li
		css={css`
			display: inline;
			cursor: pointer;
			margin-right: 0.7rem;
		`}
	>
		<a href={props.to} target="_blank" rel="noreferrer">
			{props.children}
		</a>
	</li>
);

export default function Layout({ children }) {
	return (
		<div
			css={css`
				margin: 2rem auto;
				max-width: 750px;
				padding: 0 1rem;
			`}
		>
			<header
				css={css`
					display: flex;
					justify-content: space-between;
					flex-flow: row wrap;
					margin-bottom: 1rem;
				`}
			>
				<Link to="/">
					<h2
						css={css`
							display: inline;
							font-size: 1.5rem;
							padding: 0;
						`}
					>
						<span
							css={css`
								color: #61a840;
							`}
						>
							j
						</span>
						<span
							css={css`
								color: #61a840;
							`}
						>
							o
						</span>
						<span
							css={css`
								color: #90c279;
							`}
						>
							e
						</span>
						<span
							css={css`
								color: #61a840;
							`}
						>
							y
						</span>
						<span> </span>
						<span
							css={css`
								color: #90c279;
							`}
						>
							l
						</span>
						<span
							css={css`
								color: #61a840;
							`}
						>
							i
						</span>
						<span
							css={css`
								color: #90c279;
							`}
						>
							m
						</span>
					</h2>
				</Link>
				<ul
					css={css`
						list-style: none;
						margin-left: 0;
						margin-bottom: 0;
					`}
				>
					<ListLink to="/work/">work</ListLink>
					<ListLink to="/blog/">blog</ListLink>
					<ExternalListLink to="https://github.com/joeyqlim">
						<FontAwesomeIcon icon={faGithub} aria-label="github" size="lg" />
					</ExternalListLink>
					<ExternalListLink to="https://linkedin.com/in/joeyqlim">
						<FontAwesomeIcon
							icon={faLinkedin}
							aria-label="linkedin"
							size="lg"
						/>
					</ExternalListLink>
				</ul>
			</header>
			{children}
			<Footer />
		</div>
	);
}
