import React from "react";
import { css } from "@emotion/react";

export default function Footer(props) {
	return (
		<p
			css={css`
				text-align: center;
				font-size: 0.6rem;
				margin-top: 3.5rem;
			`}
		>
			© {new Date().getFullYear()} — Designed & developed by Joey Lim
		</p>
	);
}
